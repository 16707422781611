import { generateThumbnail } from './image-service';

const convertDataUrlToFile = async (dataUrl: string, filename: string) => {
  const res = await fetch(dataUrl);
  const buf = await res.arrayBuffer();
  return new File([buf], filename, { type: 'image/jpeg' });
};

const convertFileToDataUrl = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      // @ts-ignore
      resolve(reader.result.normalize());
    };
    reader.onerror = (error) => {
      console.log('convertFileToDataUrl error');
      reject(error);
    };
  });

const convertPdfPageToImage = (
  pdf: any,
  pageNumber: number,
  fileName: string,
) =>
  new Promise((resolve) => {
    pdf.getPage(pageNumber).then((page: any) => {
      const viewport = page.getViewport({ scale: 1 });
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const renderContext = { canvasContext: ctx, viewport: viewport };

      canvas.height = viewport.height;
      canvas.width = viewport.width;
      page
        .render(renderContext)
        .promise.then(() => {
          const dataUrl = canvas.toDataURL();
          convertDataUrlToFile(dataUrl, `${fileName}-${pageNumber}.jpeg`).then(
            resolve,
          );
        })
        .catch((err) => {
          console.log('page.render(renderContext)', err);
        });
    });
  });

const convertPdfFileToImages = (file: File) => {
  // @ts-ignore
  pdfjsLib.GlobalWorkerOptions.workerSrc = `${
    process.env.NODE_ENV !== 'development'
      ? import.meta.env.VITE_PUBLIC_URL
      : ``
  }/pdf.worker.js`;
  // @ts-ignore
  const worker = new window.pdfjsLib.PDFWorker();

  return new Promise((resolve) => {
    convertFileToDataUrl(file)
      .then((dataUrl) => {
        return window.pdfjsLib
          .getDocument({ url: dataUrl, worker })
          .promise.then((pdf: any) => {
            const promises = Array.from(
              Array(pdf.numPages),
              (_, index) =>
                new Promise((resolve) => {
                  const pageNumber = index + 1;
                  convertPdfPageToImage(pdf, pageNumber, file.name)
                    .then(resolve)
                    .catch((error) => {
                      console.log('convertPdfPageToImage error', error);
                    });
                }),
            );
            Promise.all(promises)
              .then(resolve)
              .catch((error) => {
                console.log('promises error', error);
              });
          });
      })
      .catch((error) => {
        console.log('convertFileToDataUrl error', error);
      });
  });
};

export const startConvertPdfToImage = async (file: any) => {
  const result: any = await convertPdfFileToImages(file);
  return generateThumbnail(result[0]);
};
